import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';



/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const MainPage = Loadable(lazy(() => import('../views/MainPage')));
const OrgPage = Loadable(lazy(() => import('../views/OrgPage')));
const Neighbor = Loadable(lazy(() => import('../views/neighbor/NeighborMainPage')));
const NeighborTasks = Loadable(lazy(() => import('../views/neighbor/NeighborTaskPage')));
const NeighborCodes = Loadable(lazy(() => import('../views/neighbor/NeighborCodePage')));
const SmenaEdit = Loadable(lazy(() => import('../views/smena/SmenaEdit')));
const SmenaUsers = Loadable(lazy(() => import('../views/smena/SmenaUsersPage')));
const SmenaEvents = Loadable(lazy(() => import('../views/smena/SmenaEvent')));
const DozorEdit = Loadable(lazy(() => import('../views/dozor/DozorEdit')));
const DozorManage = Loadable(lazy(() => import('../views/dozor/DozorManage')));
const DemocracyQuestions = Loadable(lazy(() => import('../views/democracy/DemocracyQuestions')));
const DemocracySanctions = Loadable(lazy(() => import('../views/democracy/DemocracySanctions')));
const DemocracyManage = Loadable(lazy(() => import('../views/democracy/DemocracyManage')));
const LegendsEdit = Loadable(lazy(() => import('../views/legends/LegendsEdit')));
const KillerEdit = Loadable(lazy(() => import('../views/killer/KillerEdit')));
const FairEdit = Loadable(lazy(() => import('../views/fair/FairEdit')));

// const Users = Loadable(lazy(() => import('../views/smena/SmenaUsersPage')));

/* ****Pages***** */

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <MainPage/>},
      { path: '/org', element: <OrgPage/>},
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/smena',
    element: <FullLayout />,
    children: [
      { path: '/smena/edit', exact: true, element: <SmenaEdit/> },
      { path: '/smena/users', exact: true, element: <SmenaUsers/> },
      { path: '/smena/events', exact: true, element: <SmenaEvents/> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/dozor',
    element: <FullLayout />,
    children: [
      { path: '/dozor/edit', exact: true, element: <DozorEdit/> },
      { path: '/dozor/manage', exact: true, element:  <DozorManage/> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/legends',
    element: <FullLayout />,
    children: [
      { path: '/legends/edit', exact: true, element: <LegendsEdit/> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/killer',
    element: <FullLayout />,
    children: [
      { path: '/killer/edit', exact: true, element: <KillerEdit/> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/neighbor',
    element: <FullLayout />,
    children: [
      { path: '/neighbor', exact: true, element: <Neighbor/> },
      { path: '/neighbor/tasks', exact: true, element: <NeighborTasks/> },
      { path: '/neighbor/codes', exact: true, element:  <NeighborCodes/> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/democracy',
    element: <FullLayout />,
    children: [
      { path: '/democracy/questions', exact: true, element:  <DemocracyQuestions/> },
      { path: '/democracy/sanctions', exact: true, element: <DemocracySanctions/> },
      { path: '/democracy/manage', exact: true, element: <DemocracyManage/> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/fair',
    element: <FullLayout />,
    children: [
      { path: '/fair/edit', exact: true, element:  <FairEdit/> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <></>},
      { path: '/auth/register', element: <></> },
      { path: '/auth/login', element: <Login/> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
