import { useState } from 'react';

function UseSmenaId() {
    const getSmenaId = () => {
        const smenaId = sessionStorage.getItem('smenaId');
        return smenaId
    };

    const [smenaIdState, setSmenaIdState] = useState(getSmenaId());

    const saveSmenaIdState = smenaId => {
        sessionStorage.setItem('smenaId', smenaId);
        setSmenaIdState(smenaId);
    };

    return {
        smenaId: smenaIdState,
        setSmenaId: saveSmenaIdState
    }
}

export default UseSmenaId;
