import { useState } from 'react';
import {useNavigate} from "react-router-dom";

function UseToken() {
    const navigate = useNavigate();
    const getToken = () => {
        const tokenString = sessionStorage.getItem('token');
        return tokenString
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        sessionStorage.setItem('token', userToken);
        setToken(userToken);
        navigate("/")
    };

    return {
        currentToken: token,
        setToken: saveToken
    }
}

export default UseToken;
