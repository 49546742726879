import React, {useContext, useState} from 'react';
import {
    Box,
    Typography,
    Button,
    Stack,
} from '@mui/material';

import CustomTextField from '../../../../components/forms/theme-elements/CustomTextField';
import {AxiosPost} from "../../../axios/AxiosPost";
import {useSnackbar} from "notistack";
import {authUrl} from "../../../schema/Environment";
import {TokenContext} from "../../../schema/Contexts";
import {useNavigate} from "react-router-dom";

export default function AuthLogin () {
    const { enqueueSnackbar } = useSnackbar();
    const [currentUsername, setCurrentUsername] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [сurrentToken, setToken] = useContext(TokenContext);


    const handleClick = () => {
        console.log('handleClick AuthLogin');
        const user = { username: currentUsername, password: currentPassword};
        AxiosPost(authUrl + "/admin/org/login","POST", null, user, enqueueSnackbar)
            .then((responseData) => {
                setToken(responseData.token);
                // обрабатываем ответ от сервера
            })
            .catch((error) => {
                enqueueSnackbar(error, { variant: 'error' });
                // обрабатываем ошибку запроса
            });
    }

    return(
        <>
            <Stack>
                {/*<Box>*/}
                {/*    <Typography variant="uptitle2"*/}
                {/*                fontWeight={600} component="label" mb="5px">Если вы не имеете аккаунта, пожалуйста, обратитесь в IT*/}
                {/*        отдел</Typography>*/}
                {/*</Box>*/}
                <Box mt="1rem">
                    <CustomTextField id="username" variant="outlined" fullWidth value={currentUsername} placeholder="Имя пользователя" onChange={e => setCurrentUsername(e.target.value)}/>
                </Box>
                <Box mt="1rem">
                    <CustomTextField id="password" type="password" variant="outlined" fullWidth value={currentPassword} placeholder="Пароль" onChange={e => setCurrentPassword(e.target.value)}/>
                </Box>
                <Box mt="1rem">
                    <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth
                        onClick={handleClick}
                    >
                        Войти
                    </Button>
                </Box>
            </Stack>
        </>
    )
}
