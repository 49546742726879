export const staticPath = "https://api.solbaumanec.ru";
export const apiUrl = "https://api.solbaumanec.ru";
export const authUrl = "https://api.solbaumanec.ru";
export const democracyUrl = "https://democracy1.solbaumanec.ru";
export const dozorUrl = "https://dozor.solbaumanec.ru";

// export const staticPath = "http://localhost:8080";
// export const apiUrl = "http://localhost:8080";
// export const authUrl = "http://localhost:8080";
// export const democracyUrl = "https://democracy1.solbaumanec.ru";
// export const dozorUrl = "https://dozor.solbaumanec.ru";
