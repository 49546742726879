import React, {useState, useEffect, useContext} from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    Grid,
    useMediaQuery
} from '@mui/material';
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {ConfirmationModalContext} from "../schema/Contexts";


function ModalConfirmation () {
    console.log("ModalConfirmation")
    const { isOpen, content, handleClose, handleAction } = useContext(ConfirmationModalContext);
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const theme = useTheme();


    return (
        <Dialog open={isOpen} onClose={handleClose} PaperProps={{
            sx: {
                maxWidth: '40%'
            },
        }}>
            <DialogContent>
                {content}
                <Grid container justifyContent={!isSmallScreen ? "center" : "center"} spacing={1} sx={{mt: '1rem', mb: '1rem'}} direction="row">
                    <Grid item xs={6} sm={!isSmallScreen ? "auto" : 12}>
                        <Button variant="contained" sx={{ width: '100%', pl: '4rem', pr: '4rem', backgroundColor: theme.palette.error.dark}} onClick={handleAction}>
                            <Typography variant="button" color={theme.palette.text.contrast}>
                                Удалить
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={6} sm={!isSmallScreen ? "auto" : 12}>
                        <Button variant="contained" sx={{ width: '100%', pl: '4rem', pr: '4rem', backgroundColor: theme.palette.secondary.light}} onClick={handleClose}>
                            <Typography variant="button" color={theme.palette.text.contrast}>
                                Отмена
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default ModalConfirmation;
