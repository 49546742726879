const typography = {
  fontFamily: "'Plus Jakarta Sans', sans-serif;",
  h1: {
    fontWeight: 600,
    fontSize: '2.25rem',
    lineHeight: '2.75rem',
    fontFamily: "'Plus Jakarta Sans', sans-serif;",
  },
  h2: {
    fontWeight: 600,
    fontSize: '1.875rem',
    lineHeight: '2.25rem',
    fontFamily: "'Plus Jakarta Sans', sans-serif;",
  },
  h3: {
    fontWeight: 600,
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
    fontFamily: "'Plus Jakarta Sans', sans-serif;",
  },
  h4: {
    fontWeight: 600,
    fontSize: '1.3125rem',
    lineHeight: '1.6rem',
  },
  h5: {
    fontWeight: 600,
    fontSize: '1.125rem',
    lineHeight: '1.6rem',
  },
  h6: {
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.2rem',
  },
  button: {
    fontSize: '1.4rem',
    textTransform: 'capitalize',
    fontWeight: 500,
  },
  bodyExtraLarge: {
    fontSize: '2rem',
    fontWeight: 400,
    lineHeight: '120%',
  },
  bodyLarge: {
    fontSize: '1.75rem',
    fontWeight: 400,
    lineHeight: '120%',
  },
  body: {
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: '1.334rem',
  },
  body1: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.334rem',
  },
  body2: {
    fontSize: '0.75rem',
    letterSpacing: '0rem',
    fontWeight: 400,
    lineHeight: '1rem',
  },
  subtitle1: {
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: '0.7rem',
    fontWeight: 500,
    color: '#595959'
  },
  uptitle1: {
    fontSize: '1.3rem',
    fontWeight: 400,
  },
  uptitle2: {
    fontSize: '0.9rem',
    fontWeight: 500,
    color: '#595959'
  },
  uptitle3: {
    fontSize: '0.8rem',
    fontWeight: 500,
    color: '#595959'
  },
  uptitle4: {
    fontSize: '0.7rem',
    fontWeight: 500,
    color: '#595959'
  },
  uptitleBold1: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  uptitleBold2: {
    fontSize: '0.9rem',
    fontWeight: 600,
    color: '#595959'
  },
  uptitleBold3: {
    fontSize: '0.8rem',
    fontWeight: 600,
    color: '#595959'
  },
  uptitleBold4: {
    fontSize: '0.7rem',
    fontWeight: 600,
    color: '#595959'
  },

};

export default typography;
