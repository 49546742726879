import {PreloaderContext} from "./schema/Contexts";
import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {useContext} from "react";


export default function Preloader (){
    const [preloaderModalIsActive, setPreloaderModalIsActive] = useContext(PreloaderContext);
    if(preloaderModalIsActive)
        return (
            <div className="full-screen flex-col-vcenter-hcenter" style={{display: preloaderModalIsActive, zIndex: 1000}}>
                <CircularProgress />
            </div>
        )
}
