import React, {useState, useEffect, useContext} from 'react';
import {ConfirmationModalContext} from "../schema/Contexts";

export default function ModalConfirmationProvider ({children}) {
    const [isOpen, setIsOpen] = useState(false);
    const [content, setContent] = useState(null);
    const [action, setAction] = useState(null);

    const handleOpen = (newContent, newAction) => {
        console.log("handleOpen")
        setContent(newContent);
        setAction(() => newAction);
        setIsOpen(true);
    };

    const handleClose = () => {
        setContent(null);
        setAction(null);
        setIsOpen(false);
    };

    const handleAction = () => {
        if (action) {
            action();
        }
        handleClose();
    };

    // Значение контекста, которое будет доступно для дочерних компонентов
    const contextValue = {
        isOpen,
        content,
        handleOpen,
        handleClose,
        handleAction,
    };

    return (
        <ConfirmationModalContext.Provider value={contextValue}>
            {children}
        </ConfirmationModalContext.Provider>
    );
}
