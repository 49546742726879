import './App.css';
import {useRoutes} from 'react-router-dom';

import {CssBaseline, ThemeProvider} from "@mui/material";
import {SnackbarProvider} from "notistack";
import React, {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';


import Router from './js/schema/Router';
import { baseTheme } from "./js/schema/theme/BaseTheme";
import UseToken from "./js/auth/UseToken";
import {SmenaContext, TokenContext, PreloaderContext, ConfirmationModalContext} from "./js/schema/Contexts";

import '../src/css/custom.css';
import '../src/css/button.css';
import '../src/css/code.css';
import '../src/css/content.css';
import '../src/css/team.css';

import Login from "./js/views/authentication/Login";
import Preloader from "./js/Preloader";
import UseSmenaId from "./js/auth/UseSmenaId";
import ModalConfirmationProvider from "./js/modal/ModalConfirmationProvider";
import ModalConfirmation from "./js/modal/ModalConfirmation";



function App() {
  const routing = useRoutes(Router);
  const {currentToken, setToken} = UseToken();
  const {smenaId, setSmenaId} = UseSmenaId();
  const [preloaderModalIsActive, setPreloaderModalIsActive] = useState(false);


    if (currentToken) {
      try {
        const decodedToken = JSON.parse(atob(currentToken.split('.')[1]));
        const currentTime = Math.floor(Date.now() / 1000);

        if (decodedToken.exp < currentTime) {
          setToken();
        }
      } catch (e) {
         setToken();
      }
    }

    if(currentToken) {
      return (
          <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
            <ThemeProvider theme={baseTheme}>
              <TokenContext.Provider value={[currentToken, setToken]}>
                <SmenaContext.Provider value={[smenaId, setSmenaId]}>
                  <ModalConfirmationProvider>
                    <PreloaderContext.Provider value={[preloaderModalIsActive, setPreloaderModalIsActive]}>
                      <CssBaseline/>
                      {routing}
                      <Preloader/>
                      <ModalConfirmation/>
                    </PreloaderContext.Provider>
                  </ModalConfirmationProvider>
                </SmenaContext.Provider>
              </TokenContext.Provider>
            </ThemeProvider>

          </SnackbarProvider>
      );
    }
    else{
      return (
          <TokenContext.Provider value={[currentToken, setToken]}>
            <Login/>
          </TokenContext.Provider>
      )
    }
}

export default App;
